import {useEffect, useRef} from 'react';
import {FullScreen, useFullScreenHandle} from "react-full-screen";

const excludedButtons = ['export-pdf', 'print', 'pan', 'multi-annotations-selection'];

function configToJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return {};
  }
  return JSON.parse(str);
}

const getDownloadBtn = (instance, fileName) => ({
  type: 'custom',
  id: 'download-pdf',
  title: 'Download',
  onPress: () => {
    instance.exportPDF().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/pdf' });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const objectUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objectUrl;
        a.style = 'display: none';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        document.body.removeChild(a);
      }
    });
  }
})

const getFullScreenBtn = (fullScreenHandle) => ({
  id: "full-screen",
  type: "custom",
  title: "Fullscreen",
  onPress: () => fullScreenHandle.enter()
})

export default function PdfViewerComponent(props) {
  let instance;
  const fullScreenHandle = useFullScreenHandle();
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let PSPDFKit;

    //TODO: Fix chunk webpage path
    (async function () {
      const { disableToolbarOptions, presentationMode } = configToJSON(window.configuration) || {};

      PSPDFKit = await import('pspdfkit');

      instance = await PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document: props.document,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: 'https://tiiny.host/pdf-ui-dev/',
        licenseKey: window.licenseKey,
        toolbarPlacement: PSPDFKit.ToolbarPlacement[presentationMode ? "BOTTOM" : "TOP"],
        initialViewState: presentationMode ? new PSPDFKit.ViewState({
          scrollMode: PSPDFKit.ScrollMode.PER_SPREAD,
          layoutMode: PSPDFKit.LayoutMode.SINGLE,
        }): undefined,
        theme: presentationMode ? PSPDFKit.Theme.DARK : PSPDFKit.Theme.AUTO,
      });

      if (presentationMode) {
        excludedButtons.push('search')
      }

      let items = PSPDFKit.defaultToolbarItems.filter((item) => !excludedButtons.includes(item.type));

      // Replace download btn with custom download btn
      if (!disableToolbarOptions?.includes('export-pdf') && !presentationMode) {
        const downloadButton = getDownloadBtn(instance, props.document);
        const printButton = PSPDFKit.defaultToolbarItems.find((item) => item.type === 'print');
        items = [...items, downloadButton, printButton];
      }

      if (presentationMode) {
        const fsButton = getFullScreenBtn(fullScreenHandle);
        items.push(fsButton);
      }

      instance.setToolbarItems(items);
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, [props.document]);

  return <FullScreen handle={fullScreenHandle}>
      <div ref={containerRef} style={{ width: '100%', height: '100vh' }} />
    </FullScreen>;
}
